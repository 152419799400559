import { isPlatformBrowser, Location } from '@angular/common';
import { PLATFORM_ID, Inject, Injectable, NgZone } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({providedIn: 'root'})
export class BrowserCookiesService {
    private get isPlatformBrowser() { return isPlatformBrowser(this.platformId); }

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        @Inject(Location) private location,
        @Inject(CookieService) private cookieService
    ) { }

    Get(key: string): string {
        if (this.isPlatformBrowser) {
            return this.cookieService.get(key);
        }
        return "";
    }

    Set(key: string, value: string, expires: Date): void {
        if (this.isPlatformBrowser) {
            var isHttps = this.location.path().toLowerCase().startsWith("https://");
            this.Remove(key);
            let options = {
                expires: expires,
                secure: isHttps,
                path: '/'
            }
            this.cookieService.set(key, value, options);
        }
    }

    Remove(key: string) {    
        if (this.isPlatformBrowser) {
            this.cookieService.delete(key, '/');
        }
    }
}