import { Inject, Injectable, Optional, isDevMode } from "@angular/core";
import { PlatformHelpersService } from "../platform.helpers.module/platform.helpers.service";
import { LOGGING_ENABLED, REQUEST } from "express.tokens";
import * as Sentry from "@sentry/angular-ivy";

@Injectable({providedIn: 'root'})
export class LoggingService {
    constructor(
      private platformService: PlatformHelpersService,
      @Optional() @Inject(LOGGING_ENABLED) private loggingEnabled: string
    ) { 
      this.initializeLogging();
    }

    enableLogging: boolean = false;

    private initializeLogging(): void {
      if (this.platformService.IsBrowserPlatform){
        const searchParams = new URLSearchParams(window.location.search);
        this.enableLogging = isDevMode() || searchParams.get('enableLogging') === 'true';
      }
      else {
        this.enableLogging = isDevMode() ||this.loggingEnabled === 'true';
      }
  }

    LogInfo(data: any): void {
        console.log(JSON.stringify(data));
    }

    LogToDevConsole(message:any):void{
     
      if(this.enableLogging){      
        console.log( 'pblog:', message);
      }
    }

    LogErrorToDevConsole(message:string):void{
      if(this.enableLogging){
        console.error( 'pblog: ' + message);
      }
    }


    LogError(data: any): void {
        console.error(JSON.stringify(data));
    }

    LogSentryError(error:Error, statusCode:string, url: string, requestBody:string, errors?:any){
      try{
        if (errors){
            Sentry.captureException(error, {
              extra: {
                  statusCode: statusCode,
                  url: url,
                  requestBody: requestBody,
                  errors: errors
              }
            });
        }
        else{
          Sentry.captureException(error, {
            extra: {
                statusCode: statusCode,
                url: url,
                requestBody: requestBody,
            }
          });
        }
      }
      catch{
        Sentry.captureMessage('Log Sentry Error: Error in error handler - Error of unknown type');
      }
    }
}
