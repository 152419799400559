import { DO_NOT_HYDRATE_SUFFIX } from "src/app/services/hydration.module/model";
import { AverageAskingPriceBySizeLink } from "../services/search.module/model/averageAskingPrice";

export const QUERY_HYDRATION_KEYS = {
    GetTextResourceQuery: "GetTextResourceQuery",
    GetTextResourcesQuery: "GetTextResourcesQuery",
    PropertyForSaleToRentContainerQuery: "PropertyForSaleToRentContainerQuery",
    LocalityForSaleToRentContainerQuery: "LocalityForSaleToRentContainerQuery",
    GetHttpSearchRequestQuery: "GetHttpSearchRequestQuery",
    GetHomePageDataQuery: "GetHomePageDataQuery",
    ApplicationResourcesQuery: "ApplicationResourcesQuery",
    MeQuery: "MeQuery",
    SearchBarDropDownsQuery: "SearchBarDropDownsQuery",
    SearchBarDropDownsMultiQuery: "SearchBarDropDownsMultiQuery",
    InitializationMultiQuery: "InitializationMultiQuery",
    PropertyListingsQuery: "PropertyListingsQuery",
    AverageAskingPricesQuery: "AverageAskingPricesQuery",
    AverageAskingPriceBySizeQuery: "AverageAskingPriceBySizeQuery",
    SponsoredListingsQuery: "SponsoredListingsQuery",
    SponsoredListingsByListingIdQuery: "SponsoredListingsByListingIdQuery",
    PropertyListingDetailsQuery: "PropertyListingDetailsQuery",
    SimilarListingsQuery: "SimilarListingsQuery",
    ContactAgentQuery: "ContactAgentQuery",
    ContactAgentWithDetailsQuery: "ContactAgentWithDetailsQuery",
    MortgageQuoteQuery: "MortgageQuoteQuery",
    MyAccountQuery: "MyAccountQuery",
    GetAddressesByPostcodeQuery: "GetAddressesByPostcodeQuery",
    GetPropertyValuationAgentBranchesQuery: "GetPropertyValuationAgentBranchesQuery",
    AbTestsQuery: "AbTestsQuery",
    CampaignPerformanceQuery: "CampaignPerformanceQuery",
    CampaignPerformanceReferenceDataQuery: "CampaignPerformanceReferenceDataQuery",
    Preload: "Preload" + DO_NOT_HYDRATE_SUFFIX,
    ContentSectionWithChildrenQuery: "ContentSectionWithChildrenQuery",
    ContentArticleQuery: "ContentArticleQuery",
    ContentSectionQuery: "ContentSectionQuery",
    EnquiriesQuery: "EnquiriesQuery",
    AgentQuery: "AgentQuery",
    AgentsQuery: "AgentsQuery",
    AgentFtpUploadDetailsQuery: "AgentFtpUploadDetailsQuery",
    AgentBillingQuery: "AgentBillingQuery",
    AgentListingsQuery: "AgentListingsQuery",
    AgentListingCampaignsQuery: "AgentListingCampaignsQuery",
    AgentActivitySummaryReportQuery: "AgentActivitySummaryReportQuery",
    AgentActivityDetailsQuery: "AgentActivityDetailsQuery",
    AgentPlaceQuery: "AgentPlaceQuery",
    CampaignAnalyticsQuery: "CampaignAnalyticsQuery",
    AgentLeadsQuery: "agentLeadsQuery",
    AgentLeadQuery: "agentLeadQuery",
    GetAutoCompleteQuery: "GetAutoCompleteQuery",
    AreaGuidesAdministrativeAreasQuery: "AreaGuidesAdministrativeAreasQuery",
    AreaGuidesLocalitiesQuery: "AreaGuidesLocalitiesQuery",
    AreaGuideRatingsQuery: "AreaGuideRatingsQuery",
    UserEventsQuery: "UserEventsQuery",
    AgentNotesQuery: "AgentNotesQuery",
    AgentPackagesQuery: "AgentPackagesQuery",
    ListingAdTypesQuery: "ListingAdTypesQuery",
    AdCampaignSettingsQuery: "AdCampaignSettingsQuery",
}
