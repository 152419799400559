import { Subscription } from "rxjs";
import { KeyValuePair } from "./types";

export function CopyObject(obj: any): any {
    if (!obj) { return obj; }
    return JSON.parse(JSON.stringify(obj));
}

export function AreObjectsEqual(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function IsEmptyObject(obj) {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
            return false;
    }
    return JSON.stringify(obj) === JSON.stringify({});
}

export function DeleteProperties(obj: any, properties: Array<string>): any {
    for (var i = 0; i < properties.length; i++) {
        delete obj[properties[i]];
    }
    return obj;
}

export function InsertAt(originalString: string, stringToInsert: string, position: number) {
    return originalString.substr(0, position) + stringToInsert + originalString.substr(position);
}

export function FormatThousands(nStr: string | number, separator: string) {
    nStr += '';
    var x = nStr.toString().split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + separator + '$2');
    }
    var result = x1 + x2;
    return result.trim();
}

export function RemoveSubscriptions(subscriptions: Array<Subscription>) {
    if (subscriptions) {
        for (var i = 0; i < subscriptions.length; i++) {
            if (subscriptions[i]) {
                subscriptions[i].unsubscribe();
            }
        }
    }
}

export function SafeParseInt(value: string): number {
    if (value) { return parseInt(value); }
    return null;
}

export function SafeParseFloat(value: string): number {
    if (value) { return parseFloat(value); }
    return null;
}

export function ParseBoolean(input: any): boolean {
    if (typeof input === 'string') {
        if (input === undefined) return undefined;
        if (input === null) return null;
        return input.toLowerCase() === "true";
    }
    return input as boolean;
}

export function ParseInt(input: any): number {
    if (typeof input === 'string') {
        return SafeParseInt(input)
    }
    return input as number;
}

export function GetValue(list: Array<KeyValuePair<any, any>>, key: number): string {
    if (list) {
        var item = list.find(l => l.key == key);
        if (item) {
            return item.value;
        }
    }
    return null;
}

export function GetIndexFromKey(list: Array<KeyValuePair<any, any>>, key): number {
    if (list) {
        return list.indexOf(list.find(k => k.key == key));
    }
    return null;
}

export function GetKeyFromIndex(list: Array<KeyValuePair<any, any>>, index): number {
    if (list) {
        return list[index].key;
    }
    return null;

}

export function GetCaseInsensitiveObjectProperty(obj: any, key: string, defaultValue: any = null): any {
    for (var prop in obj) {
        if (prop.toLowerCase() === key.toLocaleLowerCase()) {
            return obj[prop];
        }
    }
    return defaultValue;
}

export function NullableToString(obj: any): any {
    if (obj) {
        return obj.toString();
    }
    return null;
}



export function Sum(numbers: Array<number>): number {
    return numbers.reduce((previous, current) => { return previous += current }, 0);
}

export function LoadDynamicScript(scriptSrc: string, onLoaded: Function, parentElement?: string, id?: string) {
    var existingScripts = document.getElementsByTagName('script');
    for (var i = 0; i < existingScripts.length; i++) {
        if (existingScripts[i].src === scriptSrc || existingScripts[i].id === id) {
            onLoaded();
            return;
        }
    }

    var head = document.getElementsByTagName(parentElement || 'head')[0];
    var script = document.createElement('script');
    script.onload = () => {
        onLoaded();
    };
    if (id) {
        script.id = id;
    }
    script.src = scriptSrc;
    script.async = true;
    head.appendChild(script)
}

export function InsertDynamicScript(scriptText: string, parentElement?: string, id?: string) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = scriptText;
    script.async = true;
    if (id) {
        script.id = id;
    }
    document.getElementsByTagName(parentElement || 'head')[0].appendChild(script);
}

export function RemoveDynamicScript(id: string, parentElement?: string) {
    var script = document.getElementById(id);
    if (script) {
        document.getElementsByTagName(parentElement || 'head')[0].removeChild(script);
    }
}