import { Injectable, Inject, Optional, PLATFORM_ID, InjectionToken } from '@angular/core';

import {Response} from 'express';
import { isPlatformServer } from '@angular/common';
import { RESPONSE } from 'express.tokens';

interface PartialResponse {
    status(code: number): this;
    redirect(code:number, redirectUrl:string);
}

@Injectable({providedIn: 'root'})
export class StatusCodeService {


    constructor(
        @Optional() @Inject(RESPONSE) private response: Response,
        @Inject(PLATFORM_ID) private platformId: Object) { }

    SetStatusCode(statusCode: number) {
       
        if (isPlatformServer(this.platformId) && this.response) {
            this.response.status(statusCode);
            if (statusCode>=500){
                this.response.setHeader('Cache-Control', 'no-cache, no-store, must-revalidate');
                this.response.setHeader('Pragma', 'no-cache');
                this.response.setHeader('Expires', '0');
            }
        }
    }

    SetRedirectUrl(url:string){
        if (isPlatformServer(this.platformId) && this.response) {
            this.response.status(301);
            this.response.setHeader('Location', url);
            
        }
    }
}