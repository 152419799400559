import { Injectable } from "@angular/core";
import { GqlResponseBase, ERROR_MESSAGE_TYPES } from "./model";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { HttpClient, HttpErrorResponse, HttpResponse, HttpHeaders } from "@angular/common/http";
import { TrueFalseMessageData, ErrorMessageData, ShowLoaderMessageData, RedirectData } from "src/app/infrastructure/message.data.types";
import { timeoutWith } from 'rxjs/operators';
import { throwError } from "rxjs";
import { AuthorizationService } from "../authorization.module/authorization.service";
import { UrlHelpersService } from "../url.helpers.module/url.helpers.service";
import { Messenger } from "../messenger.module/messenger";
import { HydrationService } from "../hydration.module/hydration.service";
import { StatusCodeService } from "../status.code.module/status.code.service";
import { PlatformHelpersService } from "../platform.helpers.module/platform.helpers.service";
import { LoggingService } from "../logging.module/logging.service";
import { environment } from "src/environments/environment";
import { catchError, map, timeout } from 'rxjs';
import { Router } from "@angular/router";

@Injectable({providedIn: 'root'})
export class GraphQLService {
    private get TIME_OUT_MS() {
        return (environment.clientUrl.indexOf("/dev.placebuzz.com") > 0 || environment.clientUrl.indexOf("/localhost") > 0) ? 30000 : 10000;
    }

    loading: boolean;

    constructor(
        private authorizationService: AuthorizationService,
        private router: Router,
        private applicationUrlsService: UrlHelpersService,
        private messenger: Messenger,
        private hydrationService: HydrationService,
        private statusCodeService: StatusCodeService,
        private platformService: PlatformHelpersService,
        private loggingService: LoggingService,
        private http: HttpClient) { }

    ExecuteQuery(hydrationKey: string, query: string, variables: any, onRetry: Function, onRetryContext?: any, showLoader?: boolean, timeOutMs?: number, suppressErrors?: boolean): Promise<GqlResponseBase> {
        var body = JSON.stringify({
            "query": query,
            "variables": variables,
        });
        return new Promise(resolve => {
            
            var key = hydrationKey;
            if (variables && !this.isEmpty(variables)){
                key += this.serializeObjectToKey(variables);
            }

            var hydratedData = this.hydrationService.GetHydrationDataByKey(key);
            if (hydratedData) {
                resolve(hydratedData);
                this.loggingService.LogToDevConsole("GRAPHQL: Returning from hydrated data : " + hydrationKey);
                return;
            }
            
            this.loggingService.LogToDevConsole("GRAPHQL: Executing query for: " + hydrationKey);
            this.ExecutePostRequest(body, onRetry, onRetryContext, showLoader, timeOutMs, suppressErrors).then(response => {
                this.loggingService.LogToDevConsole("Adding to hydration data: " + hydrationKey);
                this.hydrationService.AddToHydrationData(key, response);
                resolve(response);
            });
        });
    }

    serializeObjectToKey(obj) {
        const sortedObj = this.sortObjectProperties(obj);
        const jsonString = JSON.stringify(sortedObj);
        return jsonString;
    }

    isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    sortObjectProperties(obj) {
        return Object.keys(obj).sort().reduce((result, key) => {
            result[key] = obj[key];
            return result;
        }, {});
    }

    ExecuteMutation(mutation: string, input: any, onRetry: Function, onRetryContext?: any, showLoader?: boolean, timeOutMs?: number, suppressErrors?: boolean): Promise<GqlResponseBase> {
        var body = JSON.stringify({
            "query": mutation,
            "variables": JSON.stringify({
                "input": input
            })
        });
        return this.ExecutePostRequest(body, onRetry, onRetryContext, showLoader, timeOutMs, suppressErrors);
    }

    ProcessResponse(targetObject: any, gqlResponseBase: GqlResponseBase, suppressErrors?: boolean): boolean {
        
        if (gqlResponseBase.errors) {
            // Process GraphQL errors
            // These are equivalent to 500's
            if (!suppressErrors) {
                this.SendErrorMessage(ERROR_MESSAGE_TYPES.Something_Went_Wrong, () => {

                    this.router.navigateByUrl("/");
            
                }, null);
            }
            return true;

        }

        var responseCodes = new Array<number>();

        for (var key in gqlResponseBase.data) {
            targetObject[key] = gqlResponseBase.data[key];

            if (targetObject[key] && targetObject[key].httpStatusCode) {
                responseCodes.push(targetObject[key].httpStatusCode);
            }

            var firstRedirect = responseCodes.find(c => c >= 300 && c < 400);
            if (firstRedirect) {
                if (firstRedirect===301)
                {
                    this.messenger.Send({
                        messageType: MESSAGE_TYPES.REDIRECT,
                        messageData: new RedirectData(gqlResponseBase.data.propertyListings.url)
                    })
                }
                else{
                    this.statusCodeService.SetStatusCode(firstRedirect);
                }
                return false;
            }

            var firstFourHundred = responseCodes.find(c => c >= 400 && c < 500);
            if (firstFourHundred) {
                this.statusCodeService.SetStatusCode(firstFourHundred);
                if (firstFourHundred === 404) {
                    this.messenger.Send({
                        messageType: MESSAGE_TYPES.FOUR_0_FOUR,
                        messageData: new TrueFalseMessageData(true)
                    })
                }
                return false;
            }

            var firstFiveHundred = responseCodes.find(c => c >= 500);
            if (firstFiveHundred) {
                if (!suppressErrors) {
                    this.statusCodeService.SetStatusCode(firstFiveHundred);
                    this.SendErrorMessage(ERROR_MESSAGE_TYPES.Something_Went_Wrong, null, null);
                }
                return false;
            }
        }
        return true;
    }

    UploadToPresignedUrl(file: Blob, url: string): Promise<string> {
        return new Promise(resolve => {
            
            this.http.put(url, file, {
                headers: {
                    'Content-Type': file.type,
                    'x-amz-acl': 'public-read'
                }
            }).toPromise().then(response => {
                resolve("OK");
            }).catch(error => {
                resolve(null);
            });
        });
    }

    private LogError(code: number, error: any, query: any) {
        this.loggingService.LogError(
            {
                title: "Error calling GraphQL", data: {
                    error: error,
                    query: query
                }
            });
    }

    private ExecutePostRequest(requestBody: string, onRetry: Function, onRetryContext: any, showLoader: boolean, timeOutMs: number, suppressErrors: boolean): Promise<GqlResponseBase> {
        return new Promise(async resolve => {
            // Start loading
            if (showLoader === undefined || showLoader === null || showLoader === true) {
                this.SendShowLoaderMessage(true);
            }

            if (!timeOutMs || timeOutMs < this.TIME_OUT_MS) {
                timeOutMs = this.TIME_OUT_MS;
            }
            
            this.authorizationService.GetAuthorizationToken().then(async accessToken => {

                
                this.http.post(this.GetGraphQlUrl(), requestBody, this.CreateRequestOptions(accessToken))
                    .pipe(timeoutWith(timeOutMs, throwError("API call timed out")))
                    .toPromise()
                    .then((response: HttpResponse<any>) => {
                        this.DetectNewVersionAndForceServerSideNavigation(response);
                        let result = new GqlResponseBase();
                        result.data = response.body.data;
                        result.errors = response.body.errors;
                        if (result.errors) {

                            const gqlError = new Error(`GraphQL Error: ${result.errors[0].message}`);
                            this.loggingService.LogSentryError(gqlError, response.status?response.status.toString():'', response.url, requestBody, result.errors);
                            
                            if (!suppressErrors) {
                                this.statusCodeService.SetStatusCode(500);
                            }
                            throw new Error(result.errors[0].message);
                        }
                        resolve(result);
                        if (showLoader === undefined || showLoader === null || showLoader === true) {
                            this.SendShowLoaderMessage(false);
                        }
                    }).catch((error: HttpErrorResponse) => {
                        this.SendShowLoaderMessage(false);
                        if (!suppressErrors) {
                            this.SendErrorMessage(ERROR_MESSAGE_TYPES.Something_Went_Wrong, onRetry, onRetryContext);
                            var statusCode = 503;
                            this.statusCodeService.SetStatusCode(statusCode);
                        }
                        if (error && error.status) {
                            statusCode = error.status;
                        }

                        var enhancedError;
                        if (error && error.statusText){
                            enhancedError = new Error(`HTTP Error: ${error.statusText}`);
                        }
                        else{
                            enhancedError = new Error(`HTTP Error`);
                        }

                        this.loggingService.LogSentryError(enhancedError, statusCode?statusCode.toString():'', error.url, requestBody); 
                        
                        resolve(new GqlResponseBase());
                        
                    });
            });
        });
    }

    
      
      

    private SendShowLoaderMessage(isVisible: boolean) {
        this.messenger.Send({
            messageType: MESSAGE_TYPES.SHOW_LOADER,
            messageData: new ShowLoaderMessageData(isVisible, "")
        });
    }

    private CreateRequestOptions(accessToken: any): { headers, observe } {
        
        var headersObj = {
            'Content-Type': 'application/json; charset=utf-8',
            'timezone-offset': new Date().getTimezoneOffset().toString()
        };

        if (accessToken) {
            headersObj["Authorization"] = "Bearer " + accessToken;
            headersObj["X_Tracking_Id"] = this.getAuthTokenPart(accessToken);
        }

        let headers = new HttpHeaders(headersObj);
        return { 'headers': headers, observe: 'response'};
    }

    private getAuthTokenPart(accessToken:string){
      try{
        if (!accessToken){
          return "";
        }

        var tokenPart = accessToken.slice(-10) + accessToken.substring(0,4);

        if (this.platformService.IsBrowserPlatform) {
          return this.platformService.Window.btoa(tokenPart);
        }
        else{
            let buff = Buffer.from(tokenPart);
            return buff.toString('base64');
        }
      }
      catch{
          return "";
      }


    }

    private GetGraphQlUrl() {
        return this.applicationUrlsService.ResolveApiAbsoluteUrl("/graphql");
    }

    private SendErrorMessage(errorType: string, retryAction: Function, retryContext: any): void {
        this.messenger.Send({
            messageType: MESSAGE_TYPES.ERROR,
            messageData: new ErrorMessageData(errorType, retryAction, retryContext)
        });
    }

    private DetectNewVersionAndForceServerSideNavigation(response: HttpResponse<any>) {
        if (this.platformService.IsBrowserPlatform) {
            var subDomain = window.location.hostname.split('.')[0].toLowerCase();
            if (subDomain === "www" || subDomain === "qa") {
                var environmentVersion = parseInt(response.headers.get("placebuzz-e-v"));
                var liveVersion = parseInt(response.headers.get("placebuzz-l-v"));
                if (environmentVersion < liveVersion) {
                    this.platformService.forceServerSideNavigation = true;
                }
            }
        }
    }
}
