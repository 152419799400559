import { Injectable, Inject, Optional } from "@angular/core";
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { ShowLoaderMessageData } from "src/app/infrastructure/message.data.types";
import { SESSION_STORAGE_KEYS } from "src/app/infrastructure/storage.keys";
import { LoginResult } from "./model/loginResult";
import { AuthorizationProperties } from "./model/authorizationProperties";
import { PlatformHelpersService } from "../platform.helpers.module/platform.helpers.service";
import { GetEndDateFromStart } from "src/app/infrastructure/date.helpers";
import { BrowserCookiesService } from "../browser.storage.module/browser.cookies.service";
import { UrlHelpersService } from "../url.helpers.module/url.helpers.service";
import { Messenger } from "../messenger.module/messenger";
import { BrowserStorageService } from "../browser.storage.module/browser.storage.service";
import { SERVER_AUTH_TOKEN } from "express.tokens";
import { LoggingService } from "../logging.module/logging.service";

@Injectable({providedIn: 'root'})
export class AuthorizationService {
    constructor(
        private httpClient: HttpClient,
        private cookieService: BrowserCookiesService,
        private urlHelpersService: UrlHelpersService,
        private messenger: Messenger,
        private platformService: PlatformHelpersService,
        private browserStorageService: BrowserStorageService,
        private loggingService: LoggingService,
        @Optional() @Inject(SERVER_AUTH_TOKEN) private serverAuthToken: string
    ) { }

    private AUTH_COOKIE_KEY = "Auth";
    private _authorizationProperties: AuthorizationProperties;
    

    get isLoggedIn() {
        
        var authorizationProperties = this.getAuthDetails();

        var returnVal =  authorizationProperties != null 
                && authorizationProperties != undefined 
                && authorizationProperties.authorizationCodeAccessToken != undefined 
                && authorizationProperties.authorizationCodeAccessToken != null;

                this.loggingService.LogToDevConsole("Auth: IsLoggedIn=" + returnVal);

        return returnVal;
    }


    getAuthDetails():AuthorizationProperties{
        
        var authorizationProperties;

        if (this._authorizationProperties){
            this.loggingService.LogToDevConsole("Auth: Getting auth details from cache");
            return this._authorizationProperties;
        }

        if (this.platformService.IsBrowserPlatform){

            this.loggingService.LogToDevConsole("Auth: Getting auth details from cookie");
            var cookie = this.cookieService.Get(this.AUTH_COOKIE_KEY);
            if (cookie){
                authorizationProperties = JSON.parse(decodeURIComponent(cookie));
            }
        }
        else{
            authorizationProperties = {clientAccessToken:this.serverAuthToken,
            clientAccessTokenExpiryDateUtc: GetEndDateFromStart(Date.now(), 30),
            authorizationCodeAccessToken:null,
            authorizationCodeRefreshToken:null,
            authorizationCodeExpiryDateUtc:null,
            errorMessage:"" }
        }

        this._authorizationProperties = authorizationProperties;

        if (!this.platformService.IsBrowserPlatform){
            this.TrackUserWithEmailTrackingId();
        }
        

        return authorizationProperties;
    }
   

    LoginUser(userName: string, password: string): Promise<LoginResult> {
        return new Promise(resolve => {
            var content = JSON.stringify({ UserName: userName, Password: password });
            this.DoLoginUser(content).then(result => {
                
                resolve(result);
                //this.TrackLoggedInSessionType(LoggedInSessionType.PasswordLogin);
            });
        });
    }

    LoginUserWithToken(token: string): Promise<LoginResult> {
        var content = JSON.stringify({ Token: token });
        return this.DoLoginUser(content);
    }


    LogoutUser(): void {
        this.cookieService.Remove(this.AUTH_COOKIE_KEY);
        this._authorizationProperties = null;
        this.ResetLoggedInSessionType();
    }

    GetAuthorizationToken(): Promise<string> {

        var self = this;
        return new Promise(resolve => {
            var authorizationPropertiesCache = this.getAuthDetails();
            
            if (authorizationPropertiesCache) {
                if (authorizationPropertiesCache.authorizationCodeAccessToken) {
                    // AuthorizationCode credentials (logged in)
                    this.loggingService.LogToDevConsole("Auth: Getting auth token - logged in");
                    if (!self.HasTokenExpired(authorizationPropertiesCache.authorizationCodeExpiryDateUtc)) {
                        resolve(authorizationPropertiesCache.authorizationCodeAccessToken);
                    }
                    else {
                        if (authorizationPropertiesCache.authorizationCodeRefreshToken) {
                            this.loggingService.LogToDevConsole("Auth: Getting auth token - refresh token");
                            // There is a refresh token so try to get a new access token
                            self.RefreshUserAuthorizationProperties().then(token => {
                                resolve(token);
                            });
                        } else {
                            // No refresh token - Something is wrong here!!!
                            this.loggingService.LogToDevConsole("Auth: Getting auth token - no refresh token");
                            self.GetClientAuthorizationProperties().then(token => {
                                resolve(token);
                            });
                        }
                    }
                } else if (authorizationPropertiesCache.clientAccessToken) {
                    // Client credentials (not logged in token)
                    if (!self.HasTokenExpired(authorizationPropertiesCache.clientAccessTokenExpiryDateUtc)) {
                        this.loggingService.LogToDevConsole("Auth: Resolving token");
                        resolve(authorizationPropertiesCache.clientAccessToken);
                    } else {
                        // Client credentials token has expired - Get a new one
                        this.loggingService.LogToDevConsole("Auth: Getting auth token - client token expired");
                        self.GetClientAuthorizationProperties().then(token => { resolve(token);});
                    }
                } else {
                    // No authorizationProperties at all, seems like a non logged in user navigated to the site - Get a fresh client credentials token
                        this.loggingService.LogToDevConsole("Auth: Getting auth token - no token");
                        self.GetClientAuthorizationProperties().then(p => {
                            resolve(p);
                        });
                   
                
                }
            } else {
                // No authorizationProperties at all, seems like a non logged in user navigated to the site - Get a fresh client credentials token
                this.loggingService.LogToDevConsole("Auth: No authorizationProperties at all - Getting auth token - no token");
                self.GetClientAuthorizationProperties().then(p => {
                    resolve(p);
                });
            }
        });
    }

    

    private isTrackingLoggedInSession: boolean = false;
    TrackLoggedInSessionType(type:string): void {
        if (this.isLoggedIn) {
            if (!this.isTrackingLoggedInSession && type) {
                if (this.platformService.IsBrowserPlatform && !this.browserStorageService.GetSession(SESSION_STORAGE_KEYS.HAS_TRACKED_LOGGED_IN_SESSION)) {
                    this.isTrackingLoggedInSession = true;
                    this.GetAuthorizationToken().then(authToken => {
                        let currentUrl = this.urlHelpersService.GetCurrentUrl();
                        this.httpClient.post(this.urlHelpersService.ResolveApiAbsoluteUrl("/account/track-logged-in-session?type=" + type + '&path=' + encodeURIComponent(currentUrl)), null, this.CreateRequestOptions(authToken))
                            .toPromise()
                            .then(() => {
                                this.browserStorageService.SetSession(SESSION_STORAGE_KEYS.HAS_TRACKED_LOGGED_IN_SESSION, true);
                                this.isTrackingLoggedInSession = false;
                            })
                    });
                }
            }
        }
    }

    private ResetLoggedInSessionType(): void {
        if (this.platformService.IsBrowserPlatform) {
            this.browserStorageService.RemoveSession(SESSION_STORAGE_KEYS.HAS_TRACKED_LOGGED_IN_SESSION);
        }
    }

    TrackUserWithEmailTrackingId() {
        let emailTrackingId = this.platformService.getEmailTrackingId();
        
        if (emailTrackingId){
            this.GetAuthorizationToken().then(authToken => {
                let currentUrl = this.urlHelpersService.GetCurrentUrl();
                this.httpClient.post(this.urlHelpersService.ResolveApiAbsoluteUrl("/account/track?type=" + '&path=' + encodeURIComponent(currentUrl) + "&cid=" + emailTrackingId), null, this.CreateRequestOptions(authToken))
                    .toPromise();
            });
        }
    }

    private DoLoginUser(content: any): Promise<LoginResult> {
        var self = this;
        return new Promise(resolve => {
            self.SetShowLoader(true);
            var result = new LoginResult();
            self.httpClient.post(self.urlHelpersService.ResolveAuthAbsoluteUrl("/auth/login"), content, self.CreateRequestOptions()).toPromise().then((response: HttpResponse<any>) => {
                    var authorizationProperties = response.body;
                    result.authorizationProperties = authorizationProperties;

                    if (result.authorizationProperties != undefined) {
                        this.cookieService.Set(this.AUTH_COOKIE_KEY, JSON.stringify(result.authorizationProperties), GetEndDateFromStart(Date.now(), 30));
                    }

                    this._authorizationProperties = result.authorizationProperties;

                    resolve(result);
                    self.SetShowLoader(false);
                })
                .catch((error: HttpErrorResponse) => {
                    result.statusCode = error.status.toString();
                    resolve(result);
                    self.SetShowLoader(false);
                });
        });
    }

   
    private GetClientAuthorizationProperties(): Promise<string> {
        var self = this;
        
        return new Promise(resolve => {

            if (!this.platformService.IsBrowserPlatform && this.serverAuthToken){
                this.loggingService.LogToDevConsole("Auth: Getting auth token from server: " + this.serverAuthToken);
                resolve(this.serverAuthToken);
            }
            else{
                self.httpClient.get(this.urlHelpersService.ResolveAuthAbsoluteUrl("/auth"), this.CreateRequestOptions())
                .toPromise()
                .then((response: HttpResponse<any>) => {
                    var authorizationProperties = response.body as AuthorizationProperties;
                    var cookieExpiry = GetEndDateFromStart(Date.now(), 30);
                    this.cookieService.Set(this.AUTH_COOKIE_KEY, JSON.stringify(authorizationProperties), cookieExpiry);
                    resolve(authorizationProperties.clientAccessToken);
                })
                .catch(e => {
                    resolve(null);
                })
            }
            
        
            
        });
    }

    private RefreshUserAuthorizationProperties(): Promise<string> {
        var self = this;
        return new Promise<string>(resolve => {
            
            var token = this.getAuthDetails();

            var content = JSON.stringify({ refreshToken: token.authorizationCodeRefreshToken });
            self.httpClient.post(self.urlHelpersService.ResolveAuthAbsoluteUrl("/auth/refresh"), content, self.CreateRequestOptions())
                .toPromise()
                .then((response: HttpResponse<any>) => {
                    var authorizationProperties = response.body;
                    
                    if (this.HasValidAuthorizationCode(authorizationProperties)) {
                        resolve(authorizationProperties.authorizationCodeAccessToken);
                    }
                    else {
                        self.GetClientAuthorizationProperties().then(token => {
                            resolve(token);
                        });
                    }
                })
                .catch(e => {
                    self.GetClientAuthorizationProperties().then(token => resolve(token));
                })
        });
    }

    private HasValidAuthorizationCode(authorizationProperties: AuthorizationProperties): boolean {
        return authorizationProperties && authorizationProperties.authorizationCodeAccessToken && authorizationProperties.authorizationCodeExpiryDateUtc && !this.HasTokenExpired(authorizationProperties.authorizationCodeExpiryDateUtc);
    }

    private HasTokenExpired(expiryDate: Date): boolean {        
        var utcNow = new Date();
        var newExpiryDate = new Date(expiryDate);
        var result = utcNow > newExpiryDate;
        this.loggingService.LogToDevConsole("Auth: HasTokenExpired=" + result);    
        return result;
    }

    CreateRequestOptions(authorizationToken: string = ""): { headers, observe } {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': this.urlHelpersService.ResolveLocalAbsoluteUrl("") });
        if (authorizationToken != "") {
            let headersObj = {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': this.urlHelpersService.ResolveLocalAbsoluteUrl(""),
                'Authorization': 'Bearer ' + authorizationToken
            };

            headers = new HttpHeaders(headersObj);
        }
        return { headers: headers, observe: 'response' };
    }

    private SetShowLoader(isVisible: boolean) {
        this.messenger.Send({
            messageType: MESSAGE_TYPES.SHOW_LOADER,
            messageData: new ShowLoaderMessageData(isVisible, "")
        });
    }




    

}
