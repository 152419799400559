export const environment = {
    production:true,
    stack: "live-black",
    authorizationServerUrl: "https://auth-live-black.placebuzz.com",
    apiUrl: "https://api-live-black.placebuzz.com",
    clientUrl: "https://www.placebuzz.com",
    mediaServerUrl: "https://media.placebuzz.com",
    cdnUrl: "https://cdn.placebuzz.com/app",
    memcache: "memcache-live-black.placebuzz.com:11211"
};
