import { GqlQueryPart } from "./model";
import { InsertAt } from "src/app/infrastructure/object.helpers";

export class GqlQueryBuilder {
    private queryVariablesConstant = "{{{QUERY_VARIABLES_PLACEHOLDER}}}";
    private queryBodiesConstant = "{{{QUERY_BODY_PLACEHOLDER}}}";
    private originalQueryVariablesIndex = 0;
    private originalQueryBodiesIndex = 0;

    query: string = "";
    variables: any;

    constructor(queryName: string) {
        this.query = "query " + queryName + "(" + this.queryVariablesConstant + "){" + this.queryBodiesConstant + "}";
        this.variables = {};
        this.originalQueryVariablesIndex = this.query.indexOf(this.queryVariablesConstant);
    }

    AddQueryPart(part: GqlQueryPart) {
        var queryVariablesIndex = this.query.indexOf(this.queryVariablesConstant);
        var queryVariables = "";
        if (part.arguments) {
            for (var j = 0; j < part.arguments.length; j++) {
                var arg = part.arguments[j];
                var variableToAdd = arg.variableName + ": " + arg.variableType;
                if (this.query.indexOf(variableToAdd) < 0) {
                    if (queryVariables !== "") { variableToAdd = "," + variableToAdd; }
                    queryVariables += variableToAdd;
                    this.variables[arg.variableName.replace("$", "")] = arg.variableValue;
                }
            }
            if (queryVariablesIndex > this.originalQueryVariablesIndex && queryVariables !== "") { queryVariables = "," + queryVariables }
            this.query = InsertAt(this.query, queryVariables, queryVariablesIndex);
        }

        if (this.originalQueryBodiesIndex === 0) {
            this.originalQueryBodiesIndex = this.query.indexOf(this.queryBodiesConstant);
        }
        var queryBodyIndex = this.query.indexOf(this.queryBodiesConstant);
        var sep = "";
        if (queryBodyIndex > this.originalQueryBodiesIndex) { sep = ", "; }
        this.query = InsertAt(this.query, sep + part.queryConstant.trim(), queryBodyIndex);
    }

    BuildMultiPartQuery(parts: Array<GqlQueryPart>): void {
        for (var i = 0; i < parts.length; i++) {
            this.AddQueryPart(parts[i]);
        }
        this.FinalizeQuery();
    }

    FinalizeQuery(): void {
        var queryVariablesIndex = this.query.indexOf(this.queryVariablesConstant);
        if (queryVariablesIndex === this.originalQueryVariablesIndex) {
            this.query = this.query.replace("(" + this.queryVariablesConstant + ")", "");
        }
        else {
            this.query = this.query.replace(this.queryBodiesConstant, "");
            this.query = this.query.replace(this.queryVariablesConstant, "");
        }
        this.query = this.query.replace(this.queryBodiesConstant, "");

    }
}