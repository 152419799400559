import { Injectable, StateKey } from "@angular/core";
import { IsEmptyObject } from "src/app/infrastructure/object.helpers";
import { DO_NOT_HYDRATE_SUFFIX } from "./model";
import { TransferState, makeStateKey } from "@angular/core";
import { PlatformHelpersService } from "../platform.helpers.module/platform.helpers.service";
import { LoggingService } from "../logging.module/logging.service";

@Injectable({providedIn: 'root'})
export class HydrationService {
    constructor(
       private platformService: PlatformHelpersService,
        private transferState: TransferState,
        private loggingService: LoggingService
    ) {

    }

    GetHydrationDataByKey(key: string): any {
        if (this.platformService.IsBrowserPlatform) {
            var stateKey = makeStateKey<any>(key);
            let result: any = null;

            this.loggingService.LogToDevConsole(`Trying to get hydration data for key: ${stateKey}`);

            if (this.transferState.hasKey(stateKey)) {
                result = this.transferState.get(stateKey, null);
                this.loggingService.LogToDevConsole(`found hydration data: ${key}`);
                this.transferState.remove(stateKey);
            };
            if (!IsEmptyObject(result)) {
                return result;
            }
            return null;
        }
    }

    AddToHydrationData(key: string, data: any): void {
        if (!this.platformService.IsBrowserPlatform && !key.endsWith(DO_NOT_HYDRATE_SUFFIX) && data) {
            var stateKey = makeStateKey<any>(key);
            if (this.transferState.hasKey(stateKey)) {
                this.transferState.remove(stateKey);
            }
            this.loggingService.LogToDevConsole(`Adding to hydration data: ${key}`);
            this.transferState.set(stateKey, data);
        }
    }


    RemoveKeysByPrefix(key: string) {
        if (this.platformService.IsBrowserPlatform) {
            this.transferState.remove(makeStateKey<any>(key));
        }
    }
}
