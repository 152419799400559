import { Injectable } from "@angular/core";
import { Location } from '@angular/common';
import { PlaceSearchCriteria } from "src/app/infrastructure/model/placeSearchCriteria";
import { SafeParseInt, SafeParseFloat, GetCaseInsensitiveObjectProperty, ParseBoolean, CopyObject } from "src/app/infrastructure/object.helpers";
import { ParsedUrl } from "./model/parsed.url";
import { PRIMARY_OUTLET, UrlSegmentGroup, Router, RouteReuseStrategy } from "@angular/router";
import { KeyValuePair } from "src/app/infrastructure/types";
import { environment } from "src/environments/environment";
import { PlatformHelpersService } from "../platform.helpers.module/platform.helpers.service";
import { ApplicationStateService } from "../application.state.module/application.state.service";

@Injectable({providedIn: 'root'})
export class UrlHelpersService {
    constructor(
        private location: Location,
        private router: Router,
        private platformHelpersService: PlatformHelpersService,
    ) {

    }

    ResolveLocalAbsoluteUrl(path: string): string {
        var clientUrl = environment.clientUrl;
        return clientUrl + path;
    }

    ResolveApiAbsoluteUrl(path: string): string {
        var apiUrl = environment.apiUrl;
        return apiUrl + path;
    }

    ResolveAuthAbsoluteUrl(path: string): string {
        var authUrl = environment.authorizationServerUrl;
        return authUrl + path;
    }

    GetCurrentUrl(): string {
        return this.location.path();
    }

    GetParsedUrl(url: string): ParsedUrl {
        var host: string = "";
        var protocol: string = "";

        var lowerCaseUrl = url.toLowerCase();
        if (lowerCaseUrl.startsWith("http://") || lowerCaseUrl.startsWith("https://")) {
            var urlSplit = lowerCaseUrl.split("://");
            protocol = urlSplit[0];
            host = urlSplit[1].split("/")[0];
            url = url.replace(protocol + "://" + host, "");
        }

        let tree = this.router.parseUrl(url);
        let segmentGroup: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];

        var segments = [];
        if (segmentGroup) {
            segments = segmentGroup.segments.map(s => s.path)
        }

        var queryParams = {};
        if (tree) {
            queryParams = tree.queryParams
        }

        return {
            protocol: protocol,
            host: host,
            path: segments,
            queryObject: queryParams
        }
    }

    GetUrlStringFromParsedUrl(parsedUrl: ParsedUrl): string {
        var protocolAndHost = "";
        if (parsedUrl.protocol && parsedUrl.protocol.length > 0 && parsedUrl.host && parsedUrl.host.length > 0) {
            protocolAndHost = parsedUrl.protocol + "://" + parsedUrl.host;
        }
        return protocolAndHost + this.router.serializeUrl(this.router.createUrlTree(parsedUrl.path, { queryParams: parsedUrl.queryObject }));
    }

    LocationUrlToPlaceSearchCriteria(): PlaceSearchCriteria {

        return this.UrlToPlaceSearchCriteria(this.location.normalize(this.location.path()));
    }

    UrlToPlaceSearchCriteria(url: string): PlaceSearchCriteria {
        let searchCriteriaInput: PlaceSearchCriteria = {
            currencyId: 58,
        };
        let parsedUrl = this.GetParsedUrl(this.location.normalize(url));


        if (parsedUrl) {
            searchCriteriaInput.countryCode = GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'CountryCode');
            searchCriteriaInput.latitude = GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'Latitude');
            searchCriteriaInput.longitude = GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'Longitude');
            searchCriteriaInput.localityId = GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'LocalityId');
            searchCriteriaInput.locationName = GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'LocationName');
            searchCriteriaInput.searchArea = GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'SearchArea');

            if (parsedUrl.path[0]=="property-for-sale"){
                searchCriteriaInput.searchTypeId = 2;
            }else{
                searchCriteriaInput.searchTypeId = 1;
            }

            

            searchCriteriaInput.maxNumberOfBedroomsId = SafeParseInt(GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'MaxNumberOfBedroomsId'));
            searchCriteriaInput.minNumberOfBedroomsId = SafeParseInt(GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'MinNumberOfBedroomsId'));
            
            
            searchCriteriaInput.administrativeAreaPathSegment = parsedUrl.path[1];
            searchCriteriaInput.localityPathSegment = parsedUrl.path[2];
            
            if (parsedUrl.path[1]=="search"){
                searchCriteriaInput.administrativeAreaPathSegment = undefined;
                searchCriteriaInput.localityPathSegment = undefined;
            }

        
            if (parsedUrl.path[3]!=="map"){
                searchCriteriaInput.subLocalityPathSegment = parsedUrl.path[3];
            }
            
            searchCriteriaInput.placeTypeId = null;
            if (GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'PlaceTypeId')) {
                var placetypeIds = GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'PlaceTypeId').split(",");
                searchCriteriaInput.placeTypeId = new Array<number>();
                for (var i = 0; i < placetypeIds.length; i++) {
                    searchCriteriaInput.placeTypeId.push(parseInt(placetypeIds[i]));
                }
            }

            searchCriteriaInput.radius = SafeParseFloat(GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'Radius'));
            searchCriteriaInput.page = SafeParseInt(GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'page'));
            searchCriteriaInput.minPrice = SafeParseInt(GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'MinPrice'));
            searchCriteriaInput.maxPrice = SafeParseInt(GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'MaxPrice'));
            searchCriteriaInput.sortType = GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'SortType');
            searchCriteriaInput.listed = SafeParseInt(GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'Listed'));
            searchCriteriaInput.placeSearchCriteriaId = SafeParseInt(GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'PlaceSearchCriteriaId'));
            searchCriteriaInput.hasAlerts = ParseBoolean(GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'HasAlerts'));
            searchCriteriaInput.type = GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'type');
            searchCriteriaInput.hasRecommendations = ParseBoolean(GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'HasRecommendations', true));
            searchCriteriaInput.saveSearch = ParseBoolean(GetCaseInsensitiveObjectProperty(parsedUrl.queryObject, 'SaveSearch', false));
        }

        this.CreateNonPlacesearchCriteriaQueryObject(searchCriteriaInput, parsedUrl.queryObject);

        return searchCriteriaInput;
    }

    private nonPlacesearchCriteriaQuery: any = null;

    CreateNonPlacesearchCriteriaQueryObject(placeSearchCriteria: PlaceSearchCriteria, queryObject: any): void {
        if (!placeSearchCriteria || !queryObject) { return; }
        var queryCopy = CopyObject(queryObject);
        for (var prop in queryCopy) {
            var valueInPSC = GetCaseInsensitiveObjectProperty(placeSearchCriteria, prop);
            if (valueInPSC !== null) {
                delete queryCopy[prop];
            }
        }
        this.nonPlacesearchCriteriaQuery = queryCopy;
        // console.log(`QUERY: ${JSON.stringify(queryObject)}, ${JSON.stringify(queryCopy)}`);
    }

    PopNonPlacesearchCriteriaQueryObject(): any {
        var result = CopyObject(this.nonPlacesearchCriteriaQuery);
        this.nonPlacesearchCriteriaQuery = null;
        return result;
    }


    PlaceSearchCriteriaToUrl(placeSearchCriteria: PlaceSearchCriteria, mode:string = "list"): ParsedUrl {
        
        let parsedUrl = this.GetParsedUrl(placeSearchCriteria.path);

        var path = parsedUrl.path;

        var queryObj:any = {};
       
        var queryString = placeSearchCriteria.path.split("?")[1];

        if (queryString){
            var queryArray = queryString.split("&");
            for (var i = 0; i < queryArray.length; i++){
                var query = queryArray[i].split("=");
                queryObj[decodeURIComponent(query[0])] = decodeURIComponent(query[1]);
            }
        }



        var url:ParsedUrl;

        if (mode=="list"){
            url = {
                path: path,
                queryObject: queryObj
                }
            }
            else {
                path.push("map");

                url = {
                    path: path,
                    queryObject: queryObj
                }
        }

        return url;
    }


    AddQueriesToPath(path: string, kvps: Array<KeyValuePair<string, string>>): string {
        if (!this.platformHelpersService.IsBrowserPlatform) { return null; }
        if (path === null) { path = this.GetCurrentUrl(); }
        path = this.location.normalize(path);
        let parsedUrl = this.GetParsedUrl(path);
        if (parsedUrl) {
            for (var i = 0; i < kvps.length; i++) {
                parsedUrl.queryObject[kvps[i].key] = kvps[i].value;
            }
            return this.GetUrlStringFromParsedUrl(parsedUrl);
        }
        return path;
    }

    RemoveQueriesFromPath(path: string, keys: Array<string>): string {
        path = this.location.normalize(path);
        let parsedUrl = this.GetParsedUrl(path);
        if (parsedUrl) {
            for (var i = 0; i < keys.length; i++) {
                var q = parsedUrl.queryObject[keys[i]];
                if (q) {
                    delete parsedUrl.queryObject[keys[i]];
                }
            }
            return this.GetUrlStringFromParsedUrl(parsedUrl);
        }
        return path;
    }

    AddQueryToLocation(key: string, value: string): void {
        if (this.platformHelpersService.IsBrowserPlatform) {
            var parsedUrl = this.GetParsedUrl(this.location.normalize(this.location.path()));
            parsedUrl.queryObject[key] = value;
            this.location.go(this.GetUrlStringFromParsedUrl(parsedUrl));
        }
    }

    RemoveQueryFromLocation(key: string): void {
        if (this.platformHelpersService.IsBrowserPlatform) {
            var parsedUrl = this.GetParsedUrl(this.location.normalize(this.location.path()));
            if (parsedUrl.queryObject[key]) {
                delete parsedUrl.queryObject[key];
            }
            this.location.go(this.GetUrlStringFromParsedUrl(parsedUrl));
        }
    }

    DoesQueryExistInLocation(key: string): boolean {
        if (this.platformHelpersService.IsBrowserPlatform) {
            var parsedUrl = this.GetParsedUrl(this.location.normalize(this.location.path()));
            return !!parsedUrl.queryObject[key];
        }
        return false;
    }

    UpdateLocation(path: string): void {
        var normalizedPath = this.location.normalize(this.AddNonPbParamsToPath(path));
        this.location.replaceState(normalizedPath);
        //(this.routeReuseStrategy as CustomReuseStrategy).latestQuery = normalizedPath;
    }

    ReplaceQueryValuesInCurrentUrl(values: [{ key: string, value: any }]): void {
        var currentUrl = this.GetCurrentUrl();
        var parsedUrl = this.GetParsedUrl(currentUrl);
        for (let kv of values) {
            delete parsedUrl.queryObject[kv.key];
            parsedUrl.queryObject[kv.key] = kv.value;
        }
        var newPath = this.location.normalize(this.GetUrlStringFromParsedUrl(parsedUrl));
        this.location.replaceState(newPath);
    }

    private AddNonPbParamsToPath(path: string): string {
        var finalPath = path;
        var parsedUrl = this.GetParsedUrl(path);
        var additionalNonPbFields = this.PopNonPlacesearchCriteriaQueryObject();
        if (additionalNonPbFields) {
            for (var prop in additionalNonPbFields) {
                parsedUrl.queryObject[prop] = additionalNonPbFields[prop];
            }
            finalPath = this.GetUrlStringFromParsedUrl(parsedUrl);
        }
        return finalPath;
    }

    RemovePopupVisible() {
        if (this.platformHelpersService.IsBrowserPlatform) {
            var parsedUrl = this.GetParsedUrl(this.GetCurrentUrl());
            if (parsedUrl.queryObject.popupvisible) {
                var clearUrl = this.RemoveQueriesFromPath(this.GetCurrentUrl(), ["popupvisible"]);
                this.location.replaceState(clearUrl);
                return;
            }
        }
    }
}