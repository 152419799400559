import { KeyValuePair } from "./types";

export function GetStartOfCurrentMonth() {
    var date = new Date(); // Current date
    date.setDate(1); // Set to the first day of the current month
    date.setHours(0, 0, 0, 0); // Start of the day
    return date;
}

export function GetEndDateFromStart(startDate, daysToAdd) {
    var date = new Date(startDate);
    date.setDate(date.getDate() + daysToAdd); // Add the specified number of days
    date.setHours(23, 59, 59, 999); // End of the day
    return date;
  }

  export function GetStartOfDay(date): string {
    var d = new Date(date);
    d.setHours(0, 0, 0, 0); // Set to start of the day
    return d.toISOString();
}

export function GetEndOfDay(date): string {
  var d = new Date(date);
  d.setHours(23, 59, 59, 999); // Set to end of the day
  return d.toISOString();
}

  export function ElapsedTimeDisplay(utcJsonFrom: string): string {
    
    try{
      const fromTimestamp = new Date(JSON.parse(utcJsonFrom));
      const currentTimestamp = new Date();
    
      const fromDay = new Date(fromTimestamp);
      fromDay.setHours(0, 0, 0, 0);
      
      const currentDay = new Date(currentTimestamp);
      currentDay.setHours(0, 0, 0, 0);
    
      const timeDifference = Math.abs(currentDay.getTime() - fromDay.getTime());
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    
      let result: string = "";
    
      if (daysDifference === 0) {
        result = "Today";
      } else if (daysDifference === 1) {
        result = "Yesterday";
      } else if (daysDifference < 7) {
        result = `${daysDifference} days ago`;
      } else if (daysDifference < 14) {
        result = "Last week";
      } else if (daysDifference < 30) {
        result = `${Math.floor(daysDifference / 7)} weeks ago`;
      } else {
        result = "Over a month ago";
      }
    
      return result;
    }
    catch(e){
      return "";
    }
   
  }

  export function ShortElapsedTimeDisplayWithTime(utcJsonFrom: string, includeTime: boolean, format?: string): string {
    
   try{

    const fromTimestamp = new Date(JSON.parse(utcJsonFrom));
    const currentTimestamp = new Date();
  
    const fromDay = new Date(fromTimestamp);
    fromDay.setHours(0, 0, 0, 0);
    
    const currentDay = new Date(currentTimestamp);
    currentDay.setHours(0, 0, 0, 0);
  
    const timeDifference = Math.abs(currentDay.getTime() - fromDay.getTime());
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
  
    let result: string = "";
  
    if (daysDifference === 0) {
      result = "Today";
    } else if (daysDifference === 1) {
      result = "Yesterday";
    } else {
      result = FormatDate(fromTimestamp, format || "DD-MM-YYYY");
    }
  
    if (includeTime) {
      result += ` ${FormatDate(fromTimestamp, "HH:mm")}`;
    }
    return result;
   }
   catch(e){
      return "";
   }
    
  
    
  }
  
  export function FormatDate(date: Date, format: string): string {
    const padZero = (num: number) => (num < 10 ? `0${num}` : `${num}`);
    const year = date.getFullYear();
    const month = padZero(date.getMonth() + 1);
    const day = padZero(date.getDate());
    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());
  
    format = format.replace("YYYY", year.toString());
    format = format.replace("MM", month);
    format = format.replace("DD", day);
    format = format.replace("HH", hours);
    format = format.replace("mm", minutes);
  
    return format;
  }

  export function CalculateDurationInDays(start: Date, end: Date): number {
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const durationInMilliseconds = end.getTime() - start.getTime();
    const durationInDays = durationInMilliseconds / millisecondsPerDay;
    return durationInDays;
  }

  export function GetStartAndEndOfMonthDateRange(month: number, year: number) {
    const startDate = new Date(year, month - 1, 1); // Months are 0-indexed in JS
    const endDate = new Date(year, month, 0); // 0th day of next month is last day of current month

    const startDateFormat = startDate.toISOString().slice(0, 19).replace('T', ' ');
    const endDateFormat = endDate.toISOString().slice(0, 19).replace('T', ' ');

    return { start: startDateFormat, end: endDateFormat };
}

export function GetLastTwelveMonthsKeyValuePairs(): Array<KeyValuePair<number, string>> {
    const dateEnd = new Date();
    const months: Array<KeyValuePair<number, string>> = [];

    for (let i = 0; i < 12; i++) {
        const monthYear = dateEnd.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit' }).replace('/', '');
        const monthYearLong = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long' }).format(dateEnd);

        months.push({ key: +monthYear, value: monthYearLong });
        dateEnd.setMonth(dateEnd.getMonth() - 1);
    }

    return months;
}