export const SESSION_STORAGE_KEYS = {
    SUPPRESS_CONFIRM_EMAIL: "suppress_confirm_email",
    MAP_TO_PROPERTY_NAVIGATION_TRACKING: "map_to_property_navigation",
    HAS_TRACKED_LOGGED_IN_SESSION: "has_tracked_logged_in_session"
}

export const BROWSER_STORAGE_KEYS = {
    LOCAL_SEARCHES: "local_searches",
    REMOTE_SEARCHES: "remote_searches",
    LATEST_SEARCH: "latest_search",
    APPLICATION_RESOURCES: "application_resources",
    MORTGAGE_CALCULATOR: "mortgage_calculator",
    LOCAL_SETTINGS: "local_settings",
    PENDING_ENQUIRIES: "pending_enquiries"
}