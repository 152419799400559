import { ResponseBase } from "src/app/infrastructure/model/responseBase";

export class GqlError {
    source: string;
    message: string;
}

export class GqlResponseBase extends ResponseBase {
    data: any;
    errors: Array<GqlError>;
}

export class GqlModelBase {
    errors: Array<GqlError>;
}

export const ERROR_MESSAGE_TYPES = {
    Connection_Timed_Out: "ConnectionTimedOut",
    Something_Went_Wrong: "SomethingWentWrong"
}

export class TextResource extends GqlModelBase {
    key: string;
    value: string;
}

export class TextResourceResponse {
    textResource: TextResource;
}

export class TextResources extends GqlModelBase {
    resourcesList: Array<TextResource>;
}

export class TextResourcesResponse {
    textResources: TextResources;
}

export interface GqlQueryArgument {
    variableName: string;
    variableType: string;
    variableValue?: any;
}

export interface GqlQueryPart {
    queryConstant: string;
    arguments?: Array<GqlQueryArgument>;
}