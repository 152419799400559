import { Inject, Injectable, PLATFORM_ID, Optional } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { REQUEST } from 'express.tokens';
import { LoggingService } from '../logging.module/logging.service';

declare var biddr: any;


@Injectable({providedIn: 'root'})
export class PlatformHelpersService {
    constructor(
        @Inject(PLATFORM_ID) private platform_id,
        @Optional() @Inject(REQUEST) private request: Request,
    ) {

        this.setAdsProviderFromQueryString();
     }

    forceServerSideNavigation: boolean = false;
    private _adsProvider:string = "";

    getEmailTrackingId():string{
        
        if (!this.IsBrowserPlatform) {
            try{
                const url = new URL(this.request.url, `http://${this.request.headers['host']}`);
                const emailTrackingId = url.searchParams.get('cid');
                return emailTrackingId;
            }
            catch{}
            return "";
            
          } else {
            const url = new URL(window.location.href);
            const emailTrackingId = url.searchParams.get('cid');
            return emailTrackingId;
          }
    }

    get IsBrowserPlatform(): boolean {
        return isPlatformBrowser(this.platform_id);
    };

    get IsMobile():boolean{
        if (this.IsBrowserPlatform){
            return this.Window.innerWidth < 768;
        }
        else
        {
            return true;
        }
    }

    get IsLiveUrl():boolean{
        if (this.IsBrowserPlatform) {
            if (this.Window.location.href.indexOf("://www")>0){
                return true;
            }
            else{
                return false;
            }
        }
        return false;
    }

    get IsLocalhost():boolean{
        if (this.IsBrowserPlatform) {
            if (this.Window.location.href.indexOf("://localhost")>0 || this.Window.location.href.indexOf("://dev.placebuzz.com")>0){
                return true;
            }
            else{
                return false;
            }
        }
        return false;
    }

    
    get IsAutomatedTestUserAgent():boolean{
        var userAgent = this.BrowserUserAgent;
        if (userAgent) {
            return userAgent.indexOf("Placebuzz-Specs")>-1;
        }
        return false;
    }

    get isMobileApp():boolean{
        var userAgent = this.BrowserUserAgent;
        let result = "";
        let userAgentSplit = (userAgent || "").split("/");
  
        let mobileAppUserAgent = userAgentSplit.find(s => s.toLowerCase().startsWith("placebuzz.mob.app-"));
        if (mobileAppUserAgent) { result = mobileAppUserAgent; }
        return result && result.length > 0;
        
    }


    get Window(): any {
        if (this.IsBrowserPlatform) { return window; }
        return {};
    }

    get IsHighDensityScreen(): boolean {
        if (!this.IsBrowserPlatform) { return false; }
        return ((this.Window.matchMedia && (this.Window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches)) || (window.devicePixelRatio && window.devicePixelRatio >= 1.25));
    }

    get IsRetinaScreen(): boolean {
        if (!this.IsBrowserPlatform) { return false; }
        return ((this.Window.matchMedia && (this.Window.matchMedia('only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)').matches)) || (window.devicePixelRatio && window.devicePixelRatio >= 2)) && /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
    }

    get SupportsTransformTranslate(): boolean {
        if (this.IsBrowserPlatform) {
            if (!this.Window.getComputedStyle) {
                return false;
            }

            var el = document.createElement('p'),
                hasTranslate = false,
                transforms = {
                    'webkitTransform': '-webkit-transform',
                    'OTransform': '-o-transform',
                    'msTransform': '-ms-transform',
                    'MozTransform': '-moz-transform',
                    'transform': 'transform'
                };

            // Add it to the body to get the computed style.
            document.body.insertBefore(el, null);

            for (var t in transforms) {
                if (el.style[t] !== undefined) {
                    el.style[t] = "translate(1px,1px)";
                    var value = this.Window.getComputedStyle(el).getPropertyValue(transforms[t]);
                    if (value !== undefined && value.length > 0 && value !== "none") {
                        hasTranslate = true;
                    }
                }
            }
            document.body.removeChild(el);
            return hasTranslate;
        }
        return false;
    }

    get Navigator(): any {
        if (this.IsBrowserPlatform) {
            return this.Window.navigator;
        }
        return null;
    }




    get BrowserUserAgent(): string {
        if (this.IsBrowserPlatform) {
            return this.Window.navigator.userAgent;
        }
        return "";
        // return this.userAgent;
    }

    get GoogleTag(): any {
        if (this.IsBrowserPlatform) {
            return this.Window.googletag;
        }
        return null;
    }


    get Biddr(): any {
        if (this.IsBrowserPlatform && biddr) {
            return biddr;
        }
        return null;
    }

    ScrollToTop() {
        if (this.IsBrowserPlatform) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    }

    ScrollToElement(elementId:string){
      if (this.IsBrowserPlatform){
        let el = document.getElementById(elementId);
        el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      }
    }

    GetElementRectangleWhenAvailable(element: any, callback: Function) {
        if (this.IsBrowserPlatform) {
            window.requestAnimationFrame(() => {
                var rect = element.getBoundingClientRect();
                if (rect.height === 0 && rect.left === 0 && rect.top === 0 && rect.width === 0) {
                    this.GetElementRectangleWhenAvailable(element, callback);
                }
                else {
                    callback(rect);
                }
            });
        }
        else {
            callback({ height: 0, left: 0, top: 0, width: 0 });
        }
    }

    GetElementRectangle(element: any) {
        if (this.IsBrowserPlatform) {
            var rect = element.getBoundingClientRect();
            return { top: rect.top, left: rect.left, width: rect.width, height: rect.height }
        }
        return null;
    }

    CurrentWindowScrollY() {
        return window.scrollY || window.pageYOffset;
    }

    IsYInViewPort(currentElementTop, threshold) {
        let totalTop = this.CurrentWindowScrollY() + (currentElementTop || 0);
        let result = (this.CurrentWindowScrollY() - threshold < totalTop) && (totalTop < this.CurrentWindowScrollY() + window.innerHeight + threshold);
        return result;
    }

    private setAdsProviderFromQueryString(): void {
        if (this.IsBrowserPlatform){
            const queryParams = new URLSearchParams(window.location.search);
    
            const provider = queryParams.get('adsprovider');
            if (provider) {
              this._adsProvider = provider;
              
            }
        }
        
      }

    get adsProvider(){
        if (this._adsProvider){
            return this._adsProvider;
        }
        else{
            this._adsProvider = "tude";
            return "tude";            
        }
    }

}
