import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';


@Injectable({providedIn: 'root'})
export class BrowserStorageService {
    private get isPlatformBrowser() { return isPlatformBrowser(this.platformId); }

    constructor(@Inject(PLATFORM_ID) private platformId) { }

    SetSession(key: string, value: any): void {
        if (this.isPlatformBrowser) {
            window.sessionStorage.setItem(key, JSON.stringify(value));
        }
    }

    GetSession(key: string) {
        if (this.isPlatformBrowser) {
            let result = window.sessionStorage.getItem(key);
            if (result) {
                return JSON.parse(result);
            }
        }
        return undefined;
    }

    RemoveSession(key: string): void {
        if (this.isPlatformBrowser) {
            window.sessionStorage.removeItem(key);
        }
    }

    SetLocal(key: string, value: any): void {
        if (this.isPlatformBrowser) {
            window.localStorage.setItem(key, JSON.stringify(value));
        }
    }


    SetLocalString(key: string, value: any): void {
      if (this.isPlatformBrowser) {
          window.localStorage.setItem(key, value);
      }
  }

    GetLocal(key: string): any {
        if (this.isPlatformBrowser) {
            let result = window.localStorage.getItem(key);
            if (result) {
                return JSON.parse(result);
            }
        }
        return undefined;
    }

    GetLocalString(key: string): any {
      if (this.isPlatformBrowser) {
          let result = window.localStorage.getItem(key);
          if (result) {
              return result;
          }
      }
      return undefined;
  }

    RemoveLocal(key: string): void {
        if (this.isPlatformBrowser) {
            window.localStorage.removeItem(key);
        }
    }
}
